import UseOnScreen from "./useOnScreen";
import { useEffect, useState } from "preact/compat";
import type { RefObject } from "preact";

const useIsVisible = (
  ref: RefObject<HTMLElement | null>,
  observerOptions?: IntersectionObserverInit,
) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { isIntersecting } = UseOnScreen(ref, observerOptions);

  useEffect(() => {
    setIsVisible(isIntersecting);
  }, [isIntersecting]);

  return { isVisible };
};

export default useIsVisible;
